import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { UserAuthService } from './user-auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AutologoutService {
  tokenValidityTimeMinutes = environment.tokenValidityTimeMinutes || 15;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private userauthservice: UserAuthService
  ) {
    this.check();
    this.initInterval();
  }

  /**
   * time interval
   */
  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 1000);
    });
  }

  /**
   * check timer
   */
  check() {
    if (localStorage.getItem('loginTime')) {
      const now = Date.now();
      const loginTime = parseInt(localStorage.getItem('loginTime')!);

      const expirationTime =
        loginTime + 60 * 1000 * this.tokenValidityTimeMinutes;

      const diff = expirationTime - now;
      const isTimeout = diff < 0;

      this.ngZone.run(() => {
        if (isTimeout && this.userauthservice.isUserLoggedIn()) {
          localStorage.removeItem('user');
          localStorage.removeItem('loginTime');
          this.router.navigate(['login']);
        }
      });
    }
  }
}
