import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserAuthService } from '../_services/user-auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: UserAuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event), // pass further respone
      catchError((error: HttpErrorResponse) => {
        if (
          error &&
          (error?.error?.error?.errorCode === 'ER-TOKEN-4000' ||
            error?.error?.error?.errorCode === 'ER-IMS-4009')
        ) {
          this.logout();
        } else if (error.status === 400) {
          if (this.authService.isUserLoggedIn())
            this.authService.getAdminByID().subscribe({
              error: () => {
                this.logout();
              },
            });
        }
        throw error;
      })
    );
  }

  logout() {
    localStorage.clear();
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 1000);
  }
}
